<template>
  <el-main>
    <page-title show-back-btn />
    <div v-loading="loading.detail" class="partition-area">
      <el-form label-width="180px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="基本信息">
          <el-col :span="12">
            <el-form-item label="用信编号">
              <p>{{ utils.isEffectiveCommon(repaymentInfo.useCode) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="资方">
              <p>{{ utils.isEffectiveCommon(repaymentInfo.capName) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="还款编号">
              <p>{{ utils.isEffectiveCommon(repaymentInfo.repaymentCode) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="放款时间">
              <p>{{ utils.dateFormat(repaymentInfo.loanAt) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="还款方式">
              <p>{{ utils.statusFormat(repaymentInfo.repaymentType, 'repaymentType') }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="应还本金(元)">
              <p>{{ utils.moneyFormat(repaymentInfo.shouldCapital, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已还本金(元)">
              <p>{{ utils.moneyFormat(repaymentInfo.realCapital, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="待还本金(元)">
              <p>{{ utils.moneyFormat(repaymentInfo.currentCapital, 2) }}</p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="已还利息(元)">
              <p>{{ utils.moneyFormat(repaymentInfo.realInterest, 2) }}</p>
            </el-form-item>
          </el-col>
        </ics-page-inner>
        <ics-page-inner title="" :show-header="true">
          <div class="partition-table">
            <table-inner v-loading="loading.list" title="还款详细记录" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :page-id="tableList.pageId" :row-key="getRowKeys" :expand-row-keys="expandKeys" @expand-change="expandChange" @changePageNum="changePageNum">
              <template slot="btn-inner" style="">
                <el-button v-loading="loading.export" type="primary" @click="exportFile">
                  导出
                </el-button>
              </template>
              <template slot="table-columns">
                <el-table-column type="expand">
                  <template slot-scope="props">
                    <el-table ref="singleTable" v-loading="expandLoading.eList" :data="planRecordList" highlight-current-row @current-change="handleCurrentChange">
                      <el-table-column type="index" width="50" />
                      <el-table-column prop="repaymentRecordCode" label="还款记录编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="repaymentDate" label="实际还款日期" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="theCapital" label="还款本金(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="theInterest" label="还款利息(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                      <el-table-column prop="recordStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'recordStatus')" show-overflow-tooltip min-width="150" />
                    </el-table>
                  </template>
                </el-table-column>
                <el-table-column prop="planCode" label="还款记录编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                <el-table-column prop="loanEnd" label="还款期限" align="center" :formatter="(row, col, cell) => utils.dateFormat(cell)" show-overflow-tooltip min-width="150" />
                <el-table-column prop="realRepaymentDay" label="实际还款日期" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                <el-table-column prop="shouldCapital" label="应还本金(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                <el-table-column prop="realCapital" label="实际已还本金(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                <el-table-column prop="shouldInterest" label="应还利息(元)" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                <el-table-column prop="currentStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'recordStatus')" show-overflow-tooltip min-width="150" />
              </template>
            </table-inner>
          </div>
          <div v-if="checkShow" class="partition-area">
            <ics-pay-confirm-inner :loading-submit="loadingSubmit" :is-confirm="isConfirm" pay-type="plan" :business-id="businessId" @submit="submitForms" @close="closeInfo" />
          </div>
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsPayConfirmInner from './components/pay-confirm-inner'
export default {
  components: { IcsPayConfirmInner },
  mixins: [routeEnterMixin],
  data() {
    return {
      repaymentCode: this.$route.query.repaymentCode,
      repaymentInfo: {},
      repaymentPlan: {},
      receivablePayableInfo: {},
      tableList: {},
      paySearchForm: {},
      multipleSelection: [],
      isConfirm: false,
      checkShow: false,
      businessId: '',
      expandChangeRow: {},
      expandedRows: [],
      // 获取row的key值
      getRowKeys(row) {
        return row.id;
      },
      expandKeys: [],
      expandLoading: {
        eList: false,
        submit: false
      },
      planRecordList: [],
      planDetail: {},
      loadingSubmit: {
        submit: false
      },
      dialog: {
        pay: {
          visible: false,
          title: '还款登记'
        }
      },
      loading: {
        export: false,
        list: false
      }
    }
  },
  created() {
    if (this.repaymentCode) {
      this.getDetail()
      this.getRecordPage(this.repaymentCode)
    }
  },
  methods: {
    getDetail() {
      this.api.payBack.payBackDetails(this.repaymentCode).then(result => {
        this.repaymentInfo = result.data.data.repaymentInfo || {}
        this.repaymentPlan = result.data.data.repaymentPlan || {}
        this.receivablePayableInfo = result.data.data.receivablePayableInfo || {}
      })
    },
    getRecordPage (planCode = null) {
      if (planCode) this.paySearchForm.repaymentCode = planCode
      this.api.payBack.listPlan(this.paySearchForm).then(result => {
        this.tableList = result.data.data
      }).catch(e => {})
    },
    changePageNum (val) {
      this.paySearchForm.pageNum = val.pageNum
      this.checkShow = false
      this.getRecordPage()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    exportFile () {
      this.loading.export = true
      this.api.payBack.exportPlan(this.repaymentCode).then(result => this.utils.exportFile(result)).finally(() => {
        this.loading.export = false
      })
    },
    expandChange (row, expandedRows) {
      this.expandChangeRow = row
      this.expandedRows = expandedRows
      this.checkShow = false
      let that = this
      this.expandLoading.eList = true
      if (expandedRows.length) {
        that.expandKeys = []
        if (row) {
          that.expandKeys.push(row.id)
          this.api.payBack.listRecordDto(row.id).then(res => {
            const data = []
            res.data.data.forEach(item => {
              const info = item.repaymentPlanRecord
              info.files = item.files
              data.push(info)
            })
            this.planRecordList = data || []
            console.log(this.planRecordList)
          }).finally(() => {
            // 解放loading
            this.expandLoading.eList = false
          })
        }
      } else {
        that.expandKeys = []
      }
    },
    handleCurrentChange (val) {
      this.checkShow = true
      if (val.recordStatus === 'Confirm') {
        this.isConfirm = true
        this.businessId = val.id
      } else {
        this.isConfirm = false
        this.businessId = val.id
      }
    },
    dialogAdd () {
      this.dialog.pay.visible = true
    },
    submitForms (formData) {
      return new Promise((resolve, reject) => {
        this.loadingSubmit.submit = true
        let data = formData || {}
        const info = {
          files: data.files,
          ownFunds: 1,
          repaymentPlanRecord: {
            id: data.id,
            collectionDate: data.collectionDate
          }
        }
        this.api.payBack.donePayConfirm(info).then(result => {
          if (result.data.success === true) {
            this.$message.success('操作成功')
            this.loadingSubmit.submit = false
            this.checkShow = false
            this.isConfirm = false
            this.expandKeys = []
            this.getDetail()
            this.getRecordPage(this.repaymentCode)
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loadingSubmit.submit = false
        })
      })
    },
    closeInfo () {
      this.checkShow = false
      this.isConfirm = false
      this.expandKeys = []
    }
  }
}
</script>

<style scoped>

</style>
